<template>
  <div class="chat is-flex is-flex-direction-column container is-max-desktop">
    <ChatNavigation :hasMessages="hasMessages" @new-chat="newChat" @get-csv="downloadCSV" @get-pdf="downloadPDF" :chatThreadId="threadId" />

    <div class="chat-container is-flex-grow-1 is-flex is-flex-direction-column" ref="ChatContainer">
      <div class="is-flex-grow-1"></div>

      <div class="has-text-centered m-6">
        <b-icon icon="sparkles" custom-class="has-text-primary fa-sharp fa-regular" type="is-text-primary" custom-size="fa-4x" style="opacity: 0.5"></b-icon>
      </div>

      <ChatThread v-if="hasMessages" :messages="messages" :isBotTyping="waitingResponse" class="px-3 m-0" />
      <ChatNew v-else v-model="message" class="px-3 m-0" />
      <PreparingChat v-if="waitingThread" />
    </div>

    <div class="block px-3 mb-3">
      <ChatInput v-model="message" @submit="sendMessage" :disabled="waitingResponse" />
      <ChatDisclaimer v-if="hasMessages" :chatUsage="totalCost" :totalUsage="remainingQuota" />
    </div>
  </div>
</template>

<script lang="ts">
import ChatDisclaimer from '@/components/Common/ChatDisclaimer.vue';
import ChatInput from '@/components/Chat/ChatInput.vue';
import ChatNavigation from '@/components/Common/ChatNavigation.vue';
import ChatNew from '@/components/Chat/ChatNew.vue';
import ChatThread from '@/components/Chat/ChatThread.vue';
import PreparingChat from '@/components/Chat/PreparingChat.vue';
import CommonFeaturesMixin from '@/mixins/commonFeaturesMixin';
import { Messages, PollResponseData } from '@/models/models';
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';

@Component({ components: { ChatDisclaimer, ChatInput, ChatNavigation, ChatNew, ChatThread, PreparingChat } })
export default class Chat extends Mixins(CommonFeaturesMixin) {
  @Ref('ChatContainer') private readonly chatContainer!: HTMLElement;

  private user: string = 'Corredor X';
  private assistant: string = 'CX Assistant';
  private pendingResponseMessage: Messages = null;
  private runId: string = null;
  private threadLoaded = false;

  public message: string = '';
  public messages: Messages[] = [];
  public threadId: string = null;
  public waitingResponse: boolean = false;
  public pollInterval: number | null = null;
  public remainingQuota: number = 0;
  public totalCost: number = 0;

  public get hasMessages(): boolean {
    return this.messages?.length > 0;
  }

  public get waitingThread(): boolean {
    return !this.threadLoaded && this.waitingResponse;
  }

  public async mounted(): Promise<void> {
    this.startPolling();
    this.newChat();
  }

  public async newChat(): Promise<void> {
    this.messages = [];
    this.pendingResponseMessage = null;
    this.message = '';

    this.runId = null;
    this.threadId = null;
    this.totalCost = 0;
    this.waitingResponse = false;
    if (this.$route.name !== 'chat') this.$router.push({ name: 'chat' });
  }

  private startPolling(): void {
    let isPooling = false;
    this.pollInterval = setInterval(async () => {
      if (isPooling) return;
      isPooling = true;
      await this.polling();
      isPooling = false;
    }, 5000);
  }

  public async downloadCSV(): Promise<void> {
    if (!this.threadId) {
      this.$buefy.notification.open({
        message: this.$t('chat.noDataToExport').toString(),
        type: 'is-danger',
      });
    } else {
      this.downloadFile(this.threadId, 'csv');
    }
  }

  public async downloadPDF(): Promise<void> {
    if (!this.threadId) {
      this.$buefy.notification.open({
        message: this.$t('chat.noDataToExport').toString(),
        type: 'is-danger',
      });
    } else {
      this.downloadFile(this.threadId, 'pdf');
    }
  }

  public stopPolling(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.pollInterval = null;
    }
  }

  private async polling(): Promise<void> {
    if (this.runId) {
      try {
        const pollResponse = await this.assistantService.pollRunData(this.threadId, this.runId);
        this.handlePollResponse(pollResponse.status, pollResponse.data, pollResponse.totalCost, pollResponse.remainingQuota);
      } catch (error) {
        console.error(error);
        this.handleResponse(this.$t('chat.messageError').toString());
      }
    }
  }

  private handlePollResponse(status: string, data?: PollResponseData, totalCost?: number, remainingQuota?: number): void {
    switch (status) {
      case 'completed':
        this.handleResponse(data?.content, totalCost, remainingQuota);
        break;
      case 'failed':
      case 'cancelled':
      case 'cancelling':
      case 'expired':
        this.handleResponse(this.$t('chat.messageError').toString());
        break;
      case 'require_action':
      case 'running':
      case 'in_progress':
      default:
        break;
    }
  }

  public async sendMessage(content: string): Promise<void> {
    const newMessage: Messages = { content, author: this.user, type: 'user', time: new Date() };
    this.pendingResponseMessage = { content: null, author: this.assistant, type: 'assistant', time: null };

    try {
      this.messages.push(newMessage);
      this.pendingResponseMessage = { content: '', author: this.assistant, type: 'assistant', time: null };
      this.waitingResponse = true;

      if (!this.threadId) this.threadId = await this.createThread();
      if (!this.runId) this.runId = await this.createRun(newMessage);

      this.threadLoaded = true;

      this.messages.push(this.pendingResponseMessage);
    } catch (error) {
      if (error.response.data.message === 'You have exceeded your instance monthly quota.') {
        this.messages.push(this.pendingResponseMessage);
        this.handleResponse(this.$t('chat.monthlyCreditsExceeded').toString());
      } else {
        this.messages.push(this.pendingResponseMessage);
        this.handleResponse(this.$t('chat.unableToProcessMessage').toString());
      }
    }
  }

  private async createRun(newMessage: Messages): Promise<string> {
    const { runId } = await this.assistantService.sendMessage(this.threadId, { content: newMessage.content, role: 'user' });
    return runId;
  }

  private async createThread(): Promise<string> {
    const { threadId } = await this.assistantService.createThread(this.$store.contextData);
    return threadId;
  }

  private handleResponse(content: string, totalCost?: number, remainingQuota?: number): void {
    this.pendingResponseMessage.content = content;
    this.totalCost = totalCost;
    this.remainingQuota = remainingQuota;
    this.pendingResponseMessage.time = new Date();
    this.pendingResponseMessage = null;
    this.waitingResponse = false;
    this.runId = null;
  }

  @Watch('messages', { immediate: true, deep: true })
  onMessagesChange() {
    this.$nextTick(() => this.chatContainer?.scrollTo({ top: this.chatContainer.scrollHeight, behavior: 'smooth' }));
  }
}
</script>

<style lang="scss" scoped>
.chat {
  height: 100vh;
}

.chat-container pre {
  white-space: pre-wrap;
}

.chat-container {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: lighten(#c4c2c2, 20%);
    border-radius: 10px;
    &:hover {
      .chat-message .notification {
        background: lighten(#c4c2c2, 10%);
        max-width: 85%;
      }
    }
  }
}
</style>
