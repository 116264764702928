import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import Vue, { CreateElement } from 'vue';
import App from './App.vue';
import './assets/main.scss';
import { Instance, User } from './models/models';
import router from './router';
import InstanceService from './services/InstanceService';
import ServicesInitializer from './services/ServicesInitializer';
import UserService from './services/UserService';
import { loadPrimaryColorStyle } from './utils/StylePrimaryColor';
import { Context } from './models/models';
import i18n from './i18n';

const createApp = (): Vue => {
  Vue.config.productionTip = false;
  Vue.use(Buefy, {
    materialDesignIcons: false,
    defaultIconPack: 'far',
  });

  const instanceService = new InstanceService();
  const userService = new UserService();
  const serviceInitializer = new ServicesInitializer();
  const instance: Instance = instanceService.getInstanceFromCookie();
  const user: User = userService.getUserTokenFromCookie();

  interface Store {
    contextData: Context;
    instanceSlug: string;
  }

  const store: Store = {
    contextData: null,
    instanceSlug: null,
  };

  loadPrimaryColorStyle(instance.mainColor);

  Vue.prototype.$store = store;

  const app: Vue = new Vue({
    render: (h: CreateElement) => h(App),
    router,
    i18n,
    provide: {
      instance,
      user,
      ...serviceInitializer.getServices(instance.token, user.token),
    },
  }).$mount('#app');

  return app;
};

createApp();
