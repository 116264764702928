import { getCookie } from '@/services/CookieService';
import { User } from '@/models/models';

export default class UserService {
  public getUserTokenFromCookie(): User {
    const slug = this.getInstanceSlugFromUrl();
    if (!slug) throw new Error('User slug not found');

    const userCookieData = getCookie(`${slug}_user`);
    if (!userCookieData) throw new Error('User cookie not found');

    const decodedUser = decodeURIComponent(userCookieData);
    return JSON.parse(decodedUser);
  }

  private getInstanceSlugFromUrl(): string {
    const slug = window.location.pathname.split('/')[1];
    if (!slug) throw new Error('Instance slug not found');
    return slug;
  }
}
