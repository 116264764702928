<template>
  <div class="hero terms">
    <b-loading :active="isLoading"></b-loading>
    <div slot="header" class="card-header">
      <p class="card-header-title is-size-5">Termos de Uso CX Assistant</p>
    </div>
    <div class="card-content">
      <div class="content">
        <h2 class="is-size-6">Obrigado por usar o CX Assistant</h2>
        <ol class="is-size-7">
          <li>
            <h6>Aceitação dos Termos:</h6>

            <p>
              Bem-vindo à SOLUCX! Ao utilizar o CX-Assistant e outros serviços da SOLUCX, incluindo aplicações de software e sites associados (referidos coletivamente como "Serviços"), você aceita estar vinculado a estes Termos de Uso. Estes Termos constituem um acordo legal
              entre você e a SOLUCX, uma empresa brasileira.
            </p>
            <ul>
              <li>Concordância com os Termos: Ao acessar ou usar nossos Serviços, você concorda em cumprir estes Termos de Uso, assim como nossas políticas e diretrizes adicionais. Se você não concorda com estes Termos, não deve acessar ou usar nossos Serviços.</li>
              <li>
                Alterações nos Termos: A SOLUCX se reserva o direito de modificar ou atualizar estes Termos a qualquer momento. Tais alterações entrarão em vigor após a publicação no site da SOLUCX ou notificação direta a você. Seu uso contínuo dos Serviços após quaisquer
                alterações indica sua aceitação dessas mudanças.
              </li>
              <li>Capacidade para Aceitar os Termos: Você afirma ter capacidade legal para celebrar este acordo e não estar impedido de aceitar estes Termos devido a quaisquer leis aplicáveis em seu país de residência.</li>
            </ul>
            <p>Ao usar os Serviços da SOLUCX, você reconhece que leu, compreendeu e concordou em ser vinculado a estes Termos de Uso.</p>
          </li>

          <li>
            <h6>Aplicabilidade dos Termos:</h6>

            <p>Ámbito de Aplicação: Estes Termos de Uso aplicam-se a todos os usuários do CX-Assistant e de outros serviços oferecidos pela SOLUCX, abrangendo aplicações de software e sites associados.</p>
            <ul>
              <li>Globalidade: Independentemente de onde você reside ou acessa nossos Serviços - seja na Área Econômica Europeia, Suíça, Reino Unido ou qualquer outra região - estes Termos são aplicáveis e regem o uso dos Serviços.</li>
              <li>
                Inclusão de Termos Específicos: Além destes Termos gerais, o uso de certos Serviços da SOLUCX, como o CX-Assistant Enterprise e nossas APIs, pode estar sujeito a Termos Comerciais adicionais. Ao usar esses Serviços específicos, você também concorda em aderir a
                esses Termos Comerciais.
              </li>
              <li>Leitura Complementar: Encorajamos você a ler nossa Política de Privacidade, que, embora não seja parte destes Termos, é crucial para entender como suas informações pessoais são coletadas, usadas e protegidas pela SOLUCX.</li>
            </ul>
            <p>Ao utilizar os Serviços da SOLUCX, você reconhece e concorda com a aplicabilidade destes Termos, que formam a base do seu acordo com a SOLUCX, independentemente da sua localização geográfica.</p>
          </li>

          <li>
            <h6>Privacidade:</h6>
            <p>A privacidade dos usuários é uma prioridade para a SOLUCX. Este item explica como suas informações pessoais são tratadas ao utilizar nossos Serviços.</p>
            <ul>
              <li>Política de Privacidade: Ao usar os Serviços da SOLUCX, você concorda com as práticas de coleta e uso de dados descritas na nossa Política de Privacidade, que é parte integrante destes Termos.</li>
              <li>Atualizações da Política: A Política de Privacidade pode ser atualizada periodicamente, refletindo mudanças nas práticas de coleta e uso de dados.</li>
              <li>Consentimento Informado: Seu uso contínuo dos Serviços após atualizações da Política de Privacidade constitui sua aceitação das práticas atualizadas.</li>
            </ul>
            <p>Ao acessar ou usar os Serviços da SOLUCX, você confirma que leu e aceita os termos da nossa Política de Privacidade.</p>
          </li>

          <li>
            <h6>Quem Somos:</h6>
            <p>Conheça a SOLUCX, nossa missão e nosso compromisso com a inovação e a ética no campo da inteligência artificial.</p>
            <ul>
              <li>Sobre a SOLUCX: Somos uma empresa brasileira especializada em pesquisa e implementação de inteligência artificial, com o objetivo de beneficiar a humanidade.</li>
              <li>Missão e Valores: Comprometemo-nos a desenvolver tecnologias de IA seguras, éticas e benéficas para todos.</li>
              <li>Mais Informações: Para saber mais sobre a SOLUCX e nosso trabalho, visite nosso site [inserir link do site da empresa].</li>
            </ul>
            <p>Ao utilizar os Serviços da SOLUCX, você se associa a uma organização dedicada à inovação responsável e ao avanço ético em inteligência artificial.</p>
          </li>

          <li>
            <h6>Registro e Acesso:</h6>
            <p>Condições para criar e manter uma conta nos Serviços da SOLUCX, enfatizando a importância de fornecer informações precisas e a responsabilidade sobre a conta.</p>
            <ul>
              <li>Idade Mínima: Você deve ter pelo menos 13 anos ou a idade mínima exigida em seu país para usar os Serviços. Menores de 18 anos devem ter permissão dos pais ou responsáveis.</li>
              <li>Registro de Conta: Ao se registrar, você deve fornecer informações precisas e completas. É proibido compartilhar credenciais ou permitir que outros usem sua conta.</li>
              <li>Responsabilidade da Conta: Você é responsável por todas as atividades realizadas em sua conta. Ao usar os Serviços em nome de terceiros, você deve ter autoridade para aceitar estes Termos em nome deles.</li>
            </ul>
            <p>Ao criar uma conta na SOLUCX, você concorda em seguir estas diretrizes e assume a responsabilidade pela sua conta e pelo uso dos Serviços.</p>
          </li>

          <li>
            <h6>Usando Nossos Serviços:</h6>
            <p>Orientações sobre o que é permitido e proibido ao usar os Serviços da SOLUCX, garantindo um uso legal e responsável.</p>
            <ul>
              <li>O que Você Pode Fazer: Sujeito à conformidade com estes Termos, você pode acessar e usar os Serviços da SOLUCX. Deve cumprir todas as leis aplicáveis e as políticas fornecidas pela SOLUCX.</li>
              <li>O que Você Não Pode Fazer: Não utilize os Serviços para atividades ilegais, prejudiciais ou abusivas. Inclui a proibição de violar direitos, modificar ou distribuir indevidamente os Serviços, e interferir na operação dos mesmos.</li>
            </ul>
            <p>Ao acessar os Serviços da SOLUCX, você se compromete a usar nossos recursos de maneira responsável e conforme estabelecido nestes Termos.</p>
          </li>

          <li>
            <h6>Serviços de Terceiros e Software:</h6>
            <p>Explicações sobre o uso de serviços e software de terceiros dentro dos Serviços da SOLUCX, incluindo diretrizes para o uso de tais recursos.</p>
            <ul>
              <li>Serviços e Software de Terceiros: Ao usar os Serviços da SOLUCX, você pode se deparar com softwares, produtos ou serviços de terceiros, que estão sujeitos a termos próprios.</li>
              <li>Responsabilidade: A SOLUCX não é responsável pelos Serviços de Terceiros ou por quaisquer consequências do seu uso. O usuário deve cumprir os termos desses serviços externos.</li>
              <li>Integração de Softwares: Softwares disponibilizados, incluindo atualizações automáticas, podem incluir componentes de código aberto sob suas licenças específicas.</li>
            </ul>
            <p>Ao interagir com Serviços de Terceiros ou usar softwares integrados, você reconhece e concorda com as condições estabelecidas por essas entidades externas e pela SOLUCX.</p>
          </li>

          <li>
            <h6>Feedback e Uso de Conteúdo:</h6>
            <p>Orientações sobre como a SOLUCX lida com o feedback dos usuários e o uso do conteúdo fornecido pelos usuários nos Serviços.</p>
            <ul>
              <li>Feedback: Qualquer feedback fornecido por você pode ser usado pela SOLUCX sem restrições ou compensação, contribuindo para a melhoria dos Serviços.</li>
              <li>Seu Conteúdo: Ao fornecer conteúdo, você é responsável por garantir que possui os direitos necessários e que seu conteúdo não viola os Termos ou leis aplicáveis.</li>
              <li>Uso do Conteúdo pela SOLUCX: O conteúdo fornecido pode ser usado pela SOLUCX para manter, desenvolver e melhorar os Serviços, sempre respeitando a lei e os termos estabelecidos.</li>
            </ul>
            <p>Ao fornecer feedback ou conteúdo, você concorda com o uso desse material pela SOLUCX, dentro dos limites destes Termos e da lei.</p>
          </li>

          <li>
            <h6>Contas Pagas e Cobrança:</h6>
            <p>Detalhes sobre contas pagas, incluindo informações sobre cobrança, cancelamento e alterações nos preços dos serviços oferecidos pela SOLUCX.</p>
            <ul>
              <li>Informações de Cobrança: Ao adquirir serviços pagos, é necessário fornecer informações de cobrança precisas e um método de pagamento válido. As cobranças são feitas automaticamente conforme as renovações acordadas.</li>
              <li>Responsabilidade por Impostos: Você é responsável por todos os impostos aplicáveis, e estes serão cobrados conforme necessário.</li>
              <li>Condições de Cancelamento: Você pode cancelar sua assinatura a qualquer momento, mas os pagamentos já efetuados não são reembolsáveis, exceto conforme exigido por lei.</li>
              <li>Alterações nos Preços: A SOLUCX reserva-se o direito de alterar os preços. Qualquer aumento será comunicado com pelo menos 30 dias de antecedência.</li>
            </ul>
            <p>Ao se inscrever em uma conta paga, você concorda com os termos de cobrança e cancelamento estabelecidos pela SOLUCX.</p>
          </li>

          <li>
            <h6>Encerramento e Suspensão de Contas:</h6>
            <p>Condições sob as quais uma conta pode ser suspensa ou encerrada, bem como os direitos dos usuários em caso de suspensão ou encerramento.</p>
            <ul>
              <li>Direito ao Encerramento: Você pode encerrar sua conta e cessar o uso dos Serviços a qualquer momento.</li>
              <li>Condições de Suspensão ou Encerramento pela SOLUCX: Sua conta pode ser suspensa ou encerrada pela SOLUCX por violação destes Termos, necessidades legais ou riscos à SOLUCX ou a terceiros.</li>
              <li>Aviso de Encerramento: Caso sua conta seja encerrada devido a inatividade ou por outros motivos definidos pela SOLUCX, você receberá um aviso prévio.</li>
              <li>Apelo em Caso de Suspensão ou Encerramento: Se acreditar que sua conta foi suspensa ou encerrada por engano, você tem o direito de entrar com um recurso junto à equipe de suporte da SOLUCX.</li>
            </ul>
            <p>A SOLUCX reserva-se o direito de suspender ou encerrar contas conforme necessário, mas sempre oferecendo a possibilidade de apelo e comunicação transparente.</p>
          </li>

          <li>
            <h6>Isenção de Garantias:</h6>
            <p>Esclarecimentos sobre as garantias (ou a ausência delas) em relação aos Serviços oferecidos pela SOLUCX.</p>
            <ul>
              <li>Serviços "Como Estão": A SOLUCX fornece seus Serviços no estado em que se encontram, sem garantias expressas ou implícitas.</li>
              <li>Renúncia de Garantias: Exceto conforme proibido por lei, a SOLUCX renuncia todas as garantias relacionadas aos Serviços, incluindo garantias de comercialização, adequação a um propósito específico, e não violação.</li>
              <li>Sem Garantia de Ininterruptibilidade: Não garantimos que os Serviços serão ininterruptos, precisos, livres de erros ou que o conteúdo será seguro e não perdido ou alterado.</li>
            </ul>
            <p>Ao usar os Serviços da SOLUCX, você compreende e aceita que o uso é por sua própria conta e risco, e não deve se basear exclusivamente nos outputs dos Serviços como fonte de verdade ou informação factual.</p>
          </li>

          <li>
            <h6>Limitação de Responsabilidade:</h6>
            <p>Descrição das limitações de responsabilidade da SOLUCX em relação ao uso de seus Serviços.</p>
            <ul>
              <li>Limitação de Danos: A SOLUCX e seus afiliados não serão responsáveis por quaisquer danos indiretos, incidentais, especiais, consequenciais ou punitivos, incluindo perda de lucros, dados ou uso.</li>
              <li>Limite Máximo de Responsabilidade: A responsabilidade total da SOLUCX sob estes Termos não excederá o valor pago pelos Serviços que deram origem à reclamação ou uma quantia fixa estabelecida.</li>
              <li>Aplicabilidade Legal: As limitações de responsabilidade se aplicam na máxima extensão permitida pela lei aplicável e podem variar de acordo com a jurisdição do usuário.</li>
            </ul>
            <p>Reconhecendo estas limitações, você concorda que a SOLUCX limita sua responsabilidade em relação ao fornecimento dos Serviços conforme descrito nestes Termos.</p>
          </li>

          <li>
            <h6>Indenização:</h6>
            <p>Termos relativos à indenização por empresas ou organizações que utilizam os Serviços da SOLUCX.</p>
            <ul>
              <li>
                Obrigação de Indenizar: Se você for uma empresa ou organização, concorda em indenizar e isentar a SOLUCX, seus afiliados e colaboradores de quaisquer reivindicações de terceiros, custos, perdas ou danos decorrentes do seu uso dos Serviços ou violação destes
                Termos.
              </li>
              <li>Cobertura da Indenização: Inclui custos legais e outras despesas relacionadas a reivindicações que surgem do seu uso dos Serviços da SOLUCX, especialmente em casos de uso inadequado ou violação dos Termos.</li>
            </ul>
            <p>Ao utilizar os Serviços da SOLUCX, empresas e organizações aceitam a responsabilidade por quaisquer consequências legais ou reivindicações relacionadas ao seu uso dos Serviços.</p>
          </li>

          <li>
            <h6>Resolução de Disputas:</h6>
            <p>Procedimentos para a resolução de disputas entre você e a SOLUCX.</p>
            <ul>
              <li>Arbitragem Obrigatória: Qualquer disputa relacionada a estes Termos ou ao uso dos Serviços será resolvida por arbitragem vinculativa, exceto conforme previsto em exceções específicas.</li>
              <li>Renúncia à Ação Coletiva: Você e a SOLUCX renunciam ao direito de participar de ações coletivas, consolidadas ou representativas em relação a disputas com a empresa.</li>
              <li>Procedimento de Resolução: As disputas devem primeiro passar por uma tentativa de resolução informal antes de proceder à arbitragem.</li>
              <li>Fórum de Arbitragem: Detalhes sobre como a arbitragem será conduzida, incluindo o local e o administrador da arbitragem.</li>
            </ul>
            <p>Este item estabelece a abordagem preferencial para a resolução de quaisquer disputas que possam surgir entre você e a SOLUCX, priorizando a arbitragem como método de resolução.</p>
          </li>

          <li>
            <h6>Reclamações de Violação de Direitos Autorais:</h6>
            <p>Processo para lidar com alegações de violação de direitos autorais nos Serviços da SOLUCX.</p>
            <ul>
              <li>Notificação de Violação: Se você acredita que seus direitos autorais foram violados nos Serviços da SOLUCX, deve enviar uma notificação formal à empresa.</li>
              <li>Conteúdo Infrator: A SOLUCX pode remover ou desativar o acesso ao conteúdo supostamente infrator e, em casos de infratores reincidentes, pode encerrar contas.</li>
              <li>Informações Necessárias: A notificação deve incluir detalhes específicos, como uma descrição da obra protegida por direitos autorais, localização do material infrator e informações de contato.</li>
            </ul>
            <p>A SOLUCX leva a sério as questões de direitos autorais e responderá adequadamente às notificações de alegadas violações.</p>
          </li>

          <li>
            <h6>Termos Gerais:</h6>
            <p>Disposições gerais e informações adicionais sobre os Termos de Uso dos Serviços da SOLUCX.</p>
            <ul>
              <li>Cessão e Transferência: Os direitos ou obrigações sob estes Termos não podem ser transferidos ou cedidos por você, mas a SOLUCX pode transferi-los sem restrição.</li>
              <li>Alterações nos Termos e Serviços: A SOLUCX pode fazer alterações nos Termos ou Serviços para refletir mudanças na lei, tecnologia ou práticas de negócios. Qualquer alteração será comunicada com antecedência.</li>
              <li>Renúncia e Separabilidade: A falha da SOLUCX em aplicar qualquer disposição destes Termos não constitui uma renúncia. Se alguma parte for considerada inválida ou inexequível, as demais disposições permanecerão em vigor.</li>
              <li>Controles de Comércio e Lei Aplicável: Você deve cumprir todas as leis de comércio aplicáveis. Os Termos são regidos pela lei do estado de São Paulo, e quaisquer disputas serão resolvidas nos tribunais deste estado.</li>
            </ul>
            <p>Estes Termos Gerais fornecem um quadro para a compreensão e aceitação dos Termos de Uso, garantindo que você esteja ciente de todas as disposições legais e regulamentações pertinentes.</p>
          </li>
        </ol>
      </div>
    </div>
    <div v-if="!acceptedTerms" slot="footer" class="is-flex is-justify-content-space-between card-footer is-align-items-end p-4">
      <b-checkbox v-model="hasAcceptedTerms" type="is-success">Aceito os termos de uso</b-checkbox>
      <div>
        <b-button class="mr-2" type="is-light" size="is-small">Cancelar</b-button>
        <b-button id="confirm-btn" @click="sendAccepted(hasAcceptedTerms)" :disabled="!hasAcceptedTerms" class="ml-2" type="is-primary" size="is-small">Confirmar</b-button>
      </div>
    </div>
    <div v-if="acceptedTerms" class="is-flex is-justify-content-flex-end card-footer p-4">
      <b-button @click="sendAccepted(acceptedTerms)" class="ml-2" type="is-primary" size="is-small">Voltar</b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import CommonFeaturesMixin from '@/mixins/commonFeaturesMixin';

@Component({
  components: {},
})
export default class TermsOfUse extends Mixins(CommonFeaturesMixin) {
  public hasAcceptedTerms: boolean = false;
  public acceptedTerms: boolean = false;
  public isLoading: boolean = false;

  public async beforeMount(): Promise<void> {
    const response = await this.termsOfUseService.getTermsOfUse();
    this.acceptedTerms = response.accepted;
    this.setupContextData();
  }

  public setupContextData(): void {
    if (!this.$store.instanceSlug) {
      this.$store.instanceSlug = this.$route.params.slug;
    }

    if (!this.$store.contextData) {
      this.$store.contextData = { context: { type: 'extract', data: { ...this.$route.query } } };
    }
  }

  public async sendAccepted(hasAcceptedTerms: boolean): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.termsOfUseService.acceptTermsOfUse(hasAcceptedTerms);
      if (response === 201 || this.acceptedTerms) {
        this.$router.push({
          name: 'chat',
          params: { slug: this.$store.instanceSlug },
          query: { ...this.$store.contextData.context.data },
        });
      }
    } catch (error) {
      console.error('Erro ao aceitar os termos de uso:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>
