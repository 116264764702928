import { TermsOfUseResponse } from '@/models/models';
import BaseService from './BaseService';

export default class TermsOfUse extends BaseService {
  public async getTermsOfUse(): Promise<TermsOfUseResponse> {
    const response = await this.client.get(`/terms-of-use`);
    return response.data;
  }

  public async acceptTermsOfUse(accepted: boolean): Promise<number> {
    const response = await this.client.post(`/terms-of-use`, { accepted });
    return response.status;
  }
}
