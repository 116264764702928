import { Vue, Component, Inject } from 'vue-property-decorator';
import ThreadsService from '@/services/ThreadsService';
import AssistantService from '@/services/AssistantService';
import TermsOfUseService from '@/services/TermsOfUseService';

@Component
export default class CommonFeaturesMixin extends Vue {
  @Inject() readonly threadsService!: ThreadsService;
  @Inject() readonly assistantService!: AssistantService;
  @Inject() readonly termsOfUseService!: TermsOfUseService;

  public async downloadFile(threadId: string, fileType: 'csv' | 'pdf'): Promise<void> {
    try {
      let response;
      if (fileType === 'csv') {
        response = await this.threadsService.getThreadCsv(threadId);
      } else if (fileType === 'pdf') {
        response = await this.threadsService.getThreadPdf(threadId);
      } else {
        throw new Error('Tipo de arquivo não suportado');
      }

      if (response === undefined) {
        throw new Error('Response is undefined');
      }

      const blobType = fileType === 'csv' ? 'text/csv' : 'application/pdf';
      const blob = new Blob([response.data], { type: blobType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const defaultFileName = `download.${fileType}`;
      const archiveName = response.headers['content-disposition']?.split('filename=')[1] || defaultFileName;
      link.setAttribute('download', archiveName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Erro ao baixar o arquivo ${fileType}:`, error);
    }
  }
}
