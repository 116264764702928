import Vue from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import en from './lang/english';
import es from './lang/spanish';
import pt from './lang/portuguese';

Vue.use(VueI18n);

const messages: { [key: string]: LocaleMessageObject } = {
  en: en as unknown as LocaleMessageObject,
  es: es as unknown as LocaleMessageObject,
  pt: pt as unknown as LocaleMessageObject,
};

const dateTimeFormats: VueI18n.DateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  pt: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  es: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h24',
    },
  },
};

export function getInitialLocale(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');

  const supportedLocales = ['en', 'es', 'pt'];

  if (lang && supportedLocales.includes(lang)) {
    return lang;
  }

  const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
  const browserLocale = navigatorLocale ? navigatorLocale.split('-')[0] : 'pt';
  return supportedLocales.includes(browserLocale) ? browserLocale : 'pt';
}

const initialLocale = getInitialLocale();

const i18n = new VueI18n({
  locale: initialLocale,
  fallbackLocale: 'en',
  messages,
  dateTimeFormats,
});

export default i18n;
