<template>
  <form @submit.prevent="sendMessage">
    <b-field>
      <b-input id="chat-input" autocomplete="off" v-model="message" :disabled="disabled" rows="1" expanded :placeholder="this.$t('chatInput.placeholder')" />
      <p class="control">
        <b-button id="chat-submit" :disabled="disabled || !message" :loading="disabled" native-type="submit" type="is-primary" icon-left="arrow-up" />
      </p>
    </b-field>
  </form>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';

@Component({ components: {} })
export default class ChatInput extends Vue {
  @Prop() public readonly disabled!: boolean;
  @VModel({ required: true, type: String }) public message!: string;

  public sendMessage(): void {
    if (!this.message) return;
    this.$emit('submit', this.message.trim());
    this.message = '';
  }
}
</script>
