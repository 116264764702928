<template>
  <div class="chat is-flex is-flex-direction-column container">
    <ChatNavigation
      @new-chat="newChat"
      @get-csv="(selectedThreadId) => downloadFile(selectedThreadId, 'csv')"
      @get-pdf="(selectedThreadId) => downloadFile(selectedThreadId, 'pdf')"
      @delete-all-threads="handleDeleteAllThreads"
      @deselect-thread="deselectThread"
      :hasThreads="hasThreads"
      :selectedThreadId="selectedThreadId"
    />
    <ThreadDetails v-if="hasSelectedThread" :threadId="selectedThreadId" />
    <ThreadsHistory v-else :isLoading="isLoading" @thread-selected="handleThreadSelected" ref="threadsHistory" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import CommonFeaturesMixin from '@/mixins/commonFeaturesMixin';
import { ThreadsResponse } from '@/models/models';
import ChatNavigation from '@/components/Common/ChatNavigation.vue';
import ThreadsHistory from '@/components/Thread/ThreadsHistory.vue';
import ThreadDetails from '@/components/Thread/ThreadDetails.vue';

@Component({
  components: { ChatNavigation, ThreadsHistory, ThreadDetails },
})
export default class Threads extends Mixins(CommonFeaturesMixin) {
  public threadsResponse: ThreadsResponse = { items: [], total: 0 };
  public isLoading: boolean = false;
  public selectedThreadId: string = null;

  public async mounted(): Promise<void> {
    await this.initializeAllThreads();
  }

  public get hasThreads(): boolean {
    return this.threadsResponse.items.length > 0;
  }

  public newChat(): void {
    this.$router.push({ name: 'chat' });
  }

  public async initializeAllThreads(): Promise<void> {
    try {
      const threads = await this.threadsService.getThreads();
      this.threadsResponse = threads;
    } catch (error) {
      console.error('Erro ao inicializar a thread:', error);
    }
  }

  public handleDeleteAllThreads(): void {
    if (this.$refs.threadsHistory) {
      (this.$refs.threadsHistory as ThreadsHistory).deleteAllThreads();
    }
  }

  public handleThreadSelected(threadId: string): void {
    this.selectedThreadId = threadId;
  }

  public get hasSelectedThread(): boolean {
    return this.selectedThreadId !== null;
  }

  public deselectThread(): void {
    this.selectedThreadId = null;
  }
}
</script>
<style lang="scss" scoped>
.chat {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  .chat-container {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: lighten(#c4c2c2, 20%);
      border-radius: 10px;
    }
  }
  .card {
    border: 1px solid hsl(0, 0%, 86%);
    box-shadow: none !important;
    .content {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .ellipsis-content {
        max-width: 45ch;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
