<template>
  <div class="section">
    <div class="content">
      <ul class="cx-assistant-info fa-ul is-size-7">
        <li v-for="(info, index) in predefinedInfo" :key="index" class="my-5">
          <span class="fa-li"><i class="far has-text-primary" :class="info.icon"></i></span>
          <span>{{ info.text }}</span>
        </li>
      </ul>
      <div class="cx-assistant-messages columns is-multiline is-mobile my-4">
        <div class="column is-half" v-for="(button, index) in predefinedMessages" :key="index">
          <b-button @click="input(button.text)" type="is-primary" class="ellipsis-button is-size-7 has-text-left" outlined expanded style="white-space: no-wrap; height: 60px">
            <span class="button-content">{{ button.text }}</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { PredefinedInfo, PredefinedMessage } from '@/models/models';

@Component({ components: {} })
export default class ChatNew extends Vue {
  public get predefinedInfo(): PredefinedInfo[] {
    return [
      { text: this.$t('chatNew.filtersReflectOnAssistant') as string, icon: 'fa-filter', type: 'is-primary' },
      { text: this.$t('chatNew.aiNotPerfect') as string, icon: 'fa-triangle-exclamation', type: 'is-primary' },
      { text: this.$t('chatNew.experienceLimit') as string, icon: 'fa-database', type: 'is-primary' },
      { text: this.$t('chatNew.feedbackWelcome') as string, icon: 'fa-message-check', type: 'is-primary' },
    ];
  }

  public get predefinedMessages(): PredefinedMessage[] {
    return [
      { text: this.$t('chatNew.summaryComments') as string },
      { text: this.$t('chatNew.clientNeedsAttention') as string },
      { text: this.$t('chatNew.mostCriticizedItems') as string },
      { text: this.$t('chatNew.mostPraisedTopics') as string },
      { text: this.$t('chatNew.mostCriticizedTopics') as string },
      { text: this.$t('chatNew.importantActions') as string },
    ];
  }

  public input(message: string) {
    this.$emit('input', message);
  }
}
</script>

<style lang="scss" scoped>
.content ul {
  list-style-type: none !important;
}
.cx-assistant-messages {
  .ellipsis-button {
    max-width: 100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .button-content {
      max-width: 40ch;
      text-align: center;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
