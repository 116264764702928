import { ILanguage } from '@/models/language';
const es: ILanguage = {
  chatNavigation: {
    startNewConversation: 'Iniciar nueva conversación',
    downloadCSV: 'Descargar CSV',
    downloadPDF: 'Descargar PDF',
    deleteAllConversations: 'Eliminar todas las conversaciones',
    conversationHistory: 'Historial de conversaciones',
    backToHistory: 'Volver al historial',
    termsOfUse: 'Términos de uso',
    suggestImprovements: 'Sugerir mejoras',
  },
  feedback: {
    satisfactionLevel: '¿Cuál es su nivel de satisfacción con el CX Assistant?',
    improvementSuggestion: '¿Cómo podemos mejorar?',
    placeholderComment: 'Deje su comentario aquí...',
    submitButton: 'Enviar',
  },
  chat: {
    noDataToExport: 'No hay datos para exportar.',
    messageError: 'Hubo un error al enviar el mensaje. Por favor, inténtelo de nuevo.',
    monthlyCreditsExceeded: 'Sus créditos mensuales se han agotado, contacte con ventas para más información.',
    unableToProcessMessage: 'Lo siento, no pude procesar su mensaje. Por favor, inténtelo de nuevo.',
  },
  preparingChat: {
    preparingData: 'Preparando datos para análisis...',
    analyzingData: 'Los datos mostrados en la pantalla del extracto están siendo analizados',
  },
  chatNew: {
    summaryComments: 'Resuma los comentarios',
    clientNeedsAttention: '¿Qué cliente necesita más atención?',
    mostCriticizedItems: '¿Cuáles son los elementos más criticados?',
    mostPraisedTopics: '¿Cuáles son los temas más elogiados por los clientes?',
    mostCriticizedTopics: '¿Cuáles son los temas más criticados por los clientes?',
    importantActions: '¿Cuáles son las acciones importantes a tomar?',
    filtersReflectOnAssistant: 'Los filtros aplicados en el extracto se reflejan en los datos analizados por el asistente',
    aiNotPerfect: 'La IA no es perfecta, puede cometer errores',
    experienceLimit: 'Actualmente hay un límite de 20000 experiencias que el asistente puede analizar',
    feedbackWelcome: 'Su retroalimentación es bienvenida para ayudarnos a mejorar el asistente',
  },
  chatBody: {
    userMessage: 'Mensaje del usuario',
    botMessage: 'Mensaje del bot',
  },
  chatInput: {
    placeholder: 'Resuelva sus dudas con el CX Assistant',
  },
  chatDisclaimer: {
    possibleErrors: 'El asistente CX puede cometer errores. Considere verificar información importante.',
    chatCredits: 'Créditos del chat',
    remainingCredits: 'Créditos restantes',
  },
  threadHistory: {
    noConversations: 'No hay conversaciones en el historial',
    deleteSuccess: 'Conversación eliminada con éxito',
    deleteAllSuccess: 'Todas las conversaciones han sido eliminadas con éxito',
  },
};

export default es;
