import { AxiosResponse } from 'axios';
import BaseService from './BaseService';
import { Filters, MessagesResponse, ThreadsResponse } from '@/models/models';

export default class ThreadsService extends BaseService {
  public async getThreads(filters?: Filters): Promise<ThreadsResponse> {
    const response = await this.client.get('/threads', { params: filters });
    return response.data;
  }

  public async getThreadMessages(threadId: string): Promise<MessagesResponse[]> {
    const response = await this.client.get(`/threads/${threadId}/messages`);
    return response.data;
  }

  public async getThreadCsv(threadId: string): Promise<AxiosResponse<string>> {
    const response = await this.client.get(`/threads/${threadId}/messages/csv`, { responseType: 'blob' });
    return response;
  }

  public async getThreadPdf(threadId: string): Promise<AxiosResponse<string>> {
    const response = await this.client.get(`/threads/${threadId}/messages/pdf`, { responseType: 'blob' });
    return response;
  }
}
