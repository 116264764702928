<template>
  <div class="chat-nav">
    <div class="columns is-justify-content-space-between is-vcentered is-centered is-mobile px-3 py-3">
      <div class="column is-3">
        <b-dropdown class="nav-dropdown" :mobile-modal="false">
          <template #trigger>
            <b-button icon-left="ellipsis-vertical" type="is-white" />
          </template>
          <b-dropdown-item id="new-chat" @click="$emit('new-chat')">
            <b-icon type="is-primary" icon="edit" />
            <span>{{ $t('chatNavigation.startNewConversation') }}</span>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item @click="$emit('get-csv', selectedThreadId)" :disabled="!isDownloadEnabled" id="csv-download-button">
            <b-icon type="is-primary" icon="file-csv" />
            <span>{{ $t('chatNavigation.downloadCSV') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('get-pdf', selectedThreadId)" :disabled="!isDownloadEnabled" id="pdf-download-button">
            <b-icon type="is-primary" icon="file-pdf" />
            <span>{{ $t('chatNavigation.downloadPDF') }}</span>
          </b-dropdown-item>
          <b-dropdown-item id="delete-all-threads-button" @click="$emit('delete-all-threads')" :disabled="!hasThreads || hasSelectedThreadId">
            <b-icon type="is-primary" icon="trash" />
            <span>{{ $t('chatNavigation.deleteAllConversations') }}</span>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item id="threads-button" v-if="!selectedThreadId" has-link>
            <router-link :to="{ name: 'threads', params: { slug: this.$store.instanceSlug } }">
              <b-icon type="is-primary" icon="clock"></b-icon>
              <span>{{ $t('chatNavigation.conversationHistory') }}</span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item id="history-button" v-else @click="$emit('deselect-thread')">
            <b-icon type="is-primary" icon="arrow-left" />
            <span>{{ $t('chatNavigation.backToHistory') }}</span>
          </b-dropdown-item>
          <b-dropdown-item id="terms-of-use-button" has-link>
            <router-link :to="{ name: 'terms-of-use', params: { slug: this.$store.instanceSlug }, query: { ...this.$store.contextData.context.data } }">
              <b-icon type="is-primary" icon="ballot-check"></b-icon>
              <span class="has-text-dark">{{ $t('chatNavigation.termsOfUse') }}</span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item id="suggest-improvements-button" @click="openFeedbackModal">
            <b-icon type="is-primary" icon="comment"></b-icon>
            <span class="has-text-dark">{{ $t('chatNavigation.suggestImprovements') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="column has-text-centered">
        <h2 class="mx-2 has-text-primary has-text-weight-bold">
          <b-icon icon="sparkles" pack="fas" type="is-primary" custom-size="fa-lg"></b-icon>
          <span>CX Assistant</span>
        </h2>
      </div>
      <div class="column is-3 has-text-right"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import Feedback from '@/components/Common/Feedback.vue';

@Component({ components: { Feedback } })
export default class ChatNavigation extends Vue {
  @Prop({ type: Boolean, default: false }) public readonly hasMessages!: boolean;
  @Prop({ type: Boolean, default: false }) public readonly hasThreads!: boolean;
  @Prop({ type: String, default: '' }) public readonly chatThreadId!: string;
  @Prop({ type: String, default: '' }) public readonly selectedThreadId!: string;

  public beforeMount(): void {
    this.setupContextData();
  }
  private setupContextData(): void {
    if (!this.$store.instanceSlug) {
      this.$store.instanceSlug = this.$route.params.slug;
    }

    if (!this.$store.contextData) {
      this.$store.contextData = { context: { type: 'extract', data: { ...this.$route.query } } };
    }
  }

  public get hasSelectedThreadId(): boolean {
    return !!this.selectedThreadId;
  }

  public get isDownloadEnabled(): boolean {
    const isRouteValid = this.$route.name === 'chat' || (this.$route.name === 'threads' && !!this.selectedThreadId);
    return isRouteValid && (this.hasMessages || this.hasThreads);
  }

  public openFeedbackModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: Feedback,
      hasModalCard: false,
      customClass: 'feedback-modal',
      trapFocus: true,
      canCancel: ['button', 'outside', 'escape'],
    });
  }
}
</script>
