<template>
  <div class="has-text-centered">
    <b-notification type="is-white" class="mt-2" :closable="false">
      <div class="content pt-3">
        <p><i class="fa-sharp fa-light fa-magnifying-glass-chart fa-fade fa-4x has-text-primary"></i></p>
        <p>
          <span id="main-message" class="has-text-primary">{{ $t('preparingChat.preparingData') }}</span>
          <br />
          <span id="sub-message" class="is-size-7">{{ $t('preparingChat.analyzingData') }}</span>
        </p>
      </div>
    </b-notification>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class PreparingChat extends Vue {}
</script>
