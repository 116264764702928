<template>
  <div class="chat">
    <b-loading :active="isLoading"></b-loading>
    <div v-if="existThreads()" class="chat-container is-flex is-flex-direction-column container">
      <div v-for="thread in threadsResponse.items" :key="thread.threadId" @click="$emit('thread-selected', thread.threadId)" class="hero is-clickable">
        <div class="hero-body px-4 py-2">
          <div class="card chat-card">
            <header class="px-5 py-2">
              <p class="has-text-grey-light is-size-6">
                {{ $d(new Date(thread.createdAt), 'short') }}
              </p>
            </header>
            <div class="card-content is-flex is-justify-content-space-between py-1">
              <div class="content">
                <p class="ellipsis-content has-text-primary has-text-weight-bold">
                  {{ thread.threadName }}
                </p>
              </div>
              <b-button @click.stop="deleteThread(thread.threadId)" type="is-primary" size="is-small" icon-left="fa-solid fa-eraser"></b-button>
            </div>
            <div class="card-content py-1">
              <ChatDisclaimer :chatUsage="thread.cost" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="is-flex is-flex-direction-column container is-justify-content-center">
      <div>
        <div class="hero">
          <div class="hero-body px-6 py-6">
            <div class="content has-text-centered">
              <b-icon type="is-light" icon="folder-open" pack="fa" custom-size="fa-4x"></b-icon>
              <p class="has-text-grey-light">{{ $t('threadHistory.noConversations') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="existThreads()" class="is-flex is-justify-content-space-between px-4">
      <div>
        <b-pagination :total="threadsResponse.total" :current.sync="currentPage" :per-page="perPage" :rounded="isRounded" @change="handlePageChange" order="is-right" size="is-small"></b-pagination>
      </div>
      <div class="select-per-page">
        <b-select v-model="perPage" @input="initializeAllThreads" size="is-small">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
        </b-select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { Filters, ThreadsResponse } from '@/models/models';
import CommonFeaturesMixin from '@/mixins/commonFeaturesMixin';
import ChatDisclaimer from '@/components/Common/ChatDisclaimer.vue';

@Component({
  components: { ChatDisclaimer },
})
export default class ThreadHistory extends Mixins(CommonFeaturesMixin) {
  public threadsResponse: ThreadsResponse = { items: [], total: 0 };
  public currentPage: number = 1;
  public perPage: number = 10;
  public hasInput: boolean = true;
  public isRounded: boolean = true;
  public isLoading: boolean = false;

  public async mounted(): Promise<void> {
    await this.initializeAllThreads();
  }

  public async initializeAllThreads(): Promise<void> {
    this.isLoading = true;
    try {
      const filters: Filters = { page: this.currentPage, limit: this.perPage };
      const threads = await this.threadsService.getThreads(filters);
      this.threadsResponse = threads;
    } catch (error) {
      console.error('Erro ao inicializar a thread:', error);
    } finally {
      this.isLoading = false;
    }
  }

  public handlePageChange(newPage: number): void {
    this.currentPage = newPage;
    this.initializeAllThreads();
  }

  public existThreads(): boolean {
    return this.threadsResponse.items && this.threadsResponse.items.length > 0;
  }

  public async deleteThread(threadId: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.assistantService.deleteThread(threadId);
      await this.initializeAllThreads();
      this.$buefy.toast.open({
        message: this.$t('threadHistory.deleteSuccess').toString(),
        type: 'is-success',
        duration: 3000,
      });
    } catch (error) {
      console.error('Erro ao deletar a thread:', error);
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteAllThreads(): Promise<void> {
    try {
      this.isLoading = true;
      await this.assistantService.deleteAllThreads();
      await this.initializeAllThreads();
      this.$buefy.toast.open({
        message: this.$t('threadHistory.deleteAllSuccess').toString(),
        type: 'is-success',
        duration: 3000,
      });
    } catch (error) {
      console.error('Erro ao deletar todas as threads:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.chat {
  .card {
    border: 1px solid hsl(0, 0%, 86%);
    box-shadow: none !important;
    .content {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .ellipsis-content {
        max-width: 45ch;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
