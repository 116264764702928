<template>
  <div>
    <div class="has-text-centered">
      <p class="my-2 has-text-grey-lighter has-text-weight-bold">
        {{ $d(new Date(), 'long') }}
      </p>
    </div>
    <ChatBody :messages="messages" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import ChatBody from '@/components/Chat/ChatBody.vue';
import { Messages } from '@/models/models';

@Component({
  components: { ChatBody },
})
export default class ChatThread extends Vue {
  @Prop({ default: () => [] }) public readonly messages!: Messages[];
}
</script>
