export function getCookie(cookieName: string): string | null {
  const name = cookieName + '=';
  const cookies: string[] = document.cookie.split(';');
  let foundCookie: string | null = null;
  for (let currentCookie of cookies) {
    while (currentCookie.charAt(0) === ' ') {
      currentCookie = currentCookie.substring(1);
    }
    if (currentCookie.indexOf(name) === 0) {
      foundCookie = currentCookie.substring(name.length, currentCookie.length);
      break;
    }
  }
  return foundCookie;
}

export function setCookie(cookieName: string, cookieValue: string, expirationDays: number): void {
  const d: Date = new Date();
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires: string = 'expires=' + d.toUTCString();
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}
