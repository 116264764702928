import { getCookie } from '@/services/CookieService';
import { Instance } from '@/models/models';

export default class InstanceService {
  public getInstanceFromCookie(): Instance {
    const slug = this.getInstanceSlugFromUrl();
    if (!slug) throw new Error('Instance slug not found');

    const instanceCookieData = getCookie(`solucx_instance_${slug}`);
    if (!instanceCookieData) throw new Error('Instance cookie data not found');
    const decodedInstanceData = decodeURIComponent(instanceCookieData);
    return JSON.parse(decodedInstanceData);
  }

  private getInstanceSlugFromUrl(): string {
    const slug = window.location.pathname.split('/')[1];
    if (!slug) throw new Error('Instance slug not found');
    return slug;
  }
}
