<template>
  <footer>
    <div class="content">
      <p v-if="isRouteThreads" class="is-size-7 has-text-centered" style="opacity: 0.8">{{ $t('chatDisclaimer.possibleErrors') }}</p>
      <div class="columns is-mobile is-size-7 has-text-grey is-gapless">
        <div class="column has-text-left" v-if="chatUsage > 0">
          <span class="has-text-primary has-text-weight-bold">{{ chatUsage.toFixed(3) }}</span> {{ $t('chatDisclaimer.chatCredits') }}
        </div>
        <div class="column">&nbsp;</div>
        <div class="column has-text-right" v-if="totalUsage > 0">
          <span class="has-text-primary has-text-weight-bold">{{ totalUsage.toFixed(2) }}</span> {{ $t('chatDisclaimer.remainingCredits') }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ChatDisclaimer extends Vue {
  @Prop({ required: true, type: Number, default: 0 }) public readonly chatUsage!: number;
  @Prop({ type: Number, default: 0 }) public readonly totalUsage!: number;

  public get isRouteThreads(): boolean {
    return this.$route.name !== 'threads';
  }
}
</script>
