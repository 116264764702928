<template>
  <div class="container is-max-desktop mx-5">
    <b-loading :active="isLoading"></b-loading>
    <div class="card">
      <div class="card-content py-2">
        <div class="my-4">
          <b-field id="rating-field" class="has-text-centered" :label="$t('feedback.satisfactionLevel')">
            <b-rate id="rating" class="is-justify-content-center" v-model="rating" size="is-medium" icon-pack="fas"></b-rate>
          </b-field>
        </div>
        <div class="my-4">
          <b-field id="suggestion-field" class="has-text-centered" :label="$t('feedback.improvementSuggestion')">
            <b-input id="feedback-text" type="textarea" v-model="feedbackText" :placeholder="$t('feedback.placeholderComment')"></b-input>
          </b-field>
        </div>
      </div>
      <div class="py-2 is-justify-content-center has-text-centered">
        <b-button id="submit-button" type="is-primary" @click="handleSubmitFeedback" :disabled="!isFormValid">{{ $t('feedback.submitButton') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import FeedbackService from '@/services/FeedbackService';
import { Vue, Component, Inject } from 'vue-property-decorator';

@Component({ components: {} })
export default class Feedback extends Vue {
  @Inject() readonly feedbackService!: FeedbackService;
  public feedbackText: string | null = null;
  public rating: number | null = null;
  public isLoading: boolean = false;

  public get isFormValid(): boolean {
    return (this.rating !== null && this.rating > 0) || (this.feedbackText && this.feedbackText.trim().length > 0);
  }

  public async handleSubmitFeedback(): Promise<void> {
    if (this.isFormValid) {
      try {
        this.isLoading = true;
        await this.feedbackService.sendFeedback(this.feedbackText, this.rating);
      } catch (error) {
        console.error('Erro ao enviar feedback', error);
      } finally {
        this.isLoading = false;
        this.$emit('close');
      }
    }
  }
}
</script>
