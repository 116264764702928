import axios, { AxiosInstance } from 'axios';
import BaseService from './BaseService';
import AssistantService from '@/services/AssistantService';
import ThreadsService from '@/services/ThreadsService';
import TermsOfUseService from '@/services/TermsOfUseService';
import FeedbackService from './FeedbackService';

export default class ServicesInitializer {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = this.createClient();
  }

  public getServices(instanceToken: string, userToken: string): Record<string, BaseService> {
    return {
      assistantService: new AssistantService(this.client, instanceToken, userToken),
      threadsService: new ThreadsService(this.client, instanceToken, userToken),
      termsOfUseService: new TermsOfUseService(this.client, instanceToken, userToken),
      feedbackService: new FeedbackService(this.client, instanceToken, userToken),
    };
  }

  private createClient(): AxiosInstance {
    const baseURL = process.env.VUE_APP_BACKEND_URL || 'https://cx-assistant.preview.solucx.com.br/api';
    return axios.create({ baseURL });
  }
}
