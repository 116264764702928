import Chat from '@/views/Chat/Chat.vue';
import Threads from '@/views/Threads/Threads.vue';
import TermsOfUse from '@/views/TermsOfUse/TermsOfUse.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import UserService from '@/services/UserService';
import InstanceService from '@/services/InstanceService';
import axios from 'axios';
import TermsOfUseService from '@/services/TermsOfUseService';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    name: 'chat',
    path: '/:slug/',
    component: Chat,
    props: true,
  },
  {
    name: 'threads',
    path: '/:slug/history',
    component: Threads,
    props: true,
  },
  {
    path: '/:slug/terms-of-use',
    name: 'terms-of-use',
    component: TermsOfUse,
  },
];

const router = new VueRouter({ routes, mode: 'history' });

router.beforeEach(async (to, from, next) => {
  try {
    const userService = new UserService();
    const user = userService.getUserTokenFromCookie();

    const instanceService = new InstanceService();
    const instance = instanceService.getInstanceFromCookie();

    const baseURL = process.env.VUE_APP_BACKEND_URL || 'https://cx-assistant.preview.solucx.com.br/api';
    const axiosInstance = axios.create({ baseURL });

    const termsOfUseService = new TermsOfUseService(axiosInstance, instance.token, user.token);
    const termsOfUse = await termsOfUseService.getTermsOfUse();

    if (!termsOfUse.accepted && to.name !== 'terms-of-use') {
      next({
        name: 'terms-of-use',
        params: { slug: instance.slug },
        query: { ...to.query },
      });
    } else {
      next();
    }
  } catch (error) {
    console.error('Erro ao verificar os termos de uso', error);
    next(false);
  }
});

export default router;
