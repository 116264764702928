import { Content, Context, PollResponse, SendMessageResponse, ThreadCreationResponse } from '@/models/models';
import BaseService from './BaseService';

export default class AssistantService extends BaseService {
  public async createThread(contextData: Context): Promise<ThreadCreationResponse> {
    const response = await this.client.post('/assistant', contextData);
    return response.data;
  }

  public async sendMessage(threadId: string, content: Content): Promise<SendMessageResponse> {
    const response = await this.client.post(`/assistant/${threadId}/messages`, content);
    return response.data;
  }

  public async pollRunData(threadId: string, runId: string): Promise<PollResponse> {
    const response = await this.client.get(`/assistant/${threadId}/runs/${runId}/poll-data`, { timeout: 10000 });
    return response.data as PollResponse;
  }

  public async deleteAllThreads(): Promise<void> {
    await this.client.delete(`/assistant`);
  }

  public async deleteThread(threadId: string): Promise<void> {
    await this.client.delete(`/assistant/${threadId}`);
  }
}
