import { ILanguage } from '@/models/language';
const pt: ILanguage = {
  chatNavigation: {
    startNewConversation: 'Iniciar nova conversa',
    downloadCSV: 'Download em CSV',
    downloadPDF: 'Download em PDF',
    deleteAllConversations: 'Deletar todas as conversas',
    conversationHistory: 'Histórico de conversas',
    backToHistory: 'Voltar ao histórico',
    termsOfUse: 'Termos de uso',
    suggestImprovements: 'Sugerir melhorias',
  },
  feedback: {
    satisfactionLevel: 'Qual é o seu nível de satisfação com o CX Assistant?',
    improvementSuggestion: 'Como podemos melhorar?',
    placeholderComment: 'Deixe seu comentário aqui...',
    submitButton: 'Enviar',
  },
  chat: {
    noDataToExport: 'Não há dados para exportar.',
    messageError: 'Houve um erro ao enviar a mensagem. Por favor, tente novamente.',
    monthlyCreditsExceeded: 'Seus créditos mensais acabaram, contate o comercial para mais informações.',
    unableToProcessMessage: 'Sinto muito, não consegui processar sua mensagem. Por favor, tente novamente.',
  },
  preparingChat: {
    preparingData: 'Preparando dados para análise...',
    analyzingData: 'Os dados exibidos na tela do extrato estão sendo analisados',
  },
  chatNew: {
    summaryComments: 'Resuma os comentários',
    clientNeedsAttention: 'Qual cliente precisa de mais atenção?',
    mostCriticizedItems: 'Quais itens mais criticados?',
    mostPraisedTopics: 'Quais os assuntos mais elogiados pelos clientes?',
    mostCriticizedTopics: 'Quais os assuntos mais criticados pelos clientes?',
    importantActions: 'Quais ações importantes a tomar?',
    filtersReflectOnAssistant: 'Filtros feitos no Extrato refletem nos dados analisado pelo assistente',
    aiNotPerfect: 'A IA não é perfeita, ela pode cometer erros',
    experienceLimit: 'Atualmente existe um limite de 20000 experiências que o assistente pode analisar',
    feedbackWelcome: 'Seu feedback é bem vindo para que possamos melhorar o assistente',
  },
  chatBody: {
    userMessage: 'Mensagem do usuário',
    botMessage: 'Mensagem do bot',
  },
  chatInput: {
    placeholder: 'Tire suas dúvidas com o CX Assistant',
  },
  chatDisclaimer: {
    possibleErrors: 'CX Assistant pode cometer erros. Considere verificar informações importantes.',
    chatCredits: 'Créditos do chat',
    remainingCredits: 'Créditos restantes',
  },
  threadHistory: {
    noConversations: 'Não existem conversas no histórico',
    deleteSuccess: 'Conversa deletada com sucesso',
    deleteAllSuccess: 'Todas as conversas foram deletadas com sucesso',
  },
};

export default pt;
