import { ILanguage } from '@/models/language';
const en: ILanguage = {
  chatNavigation: {
    startNewConversation: 'Start new conversation',
    downloadCSV: 'Download CSV',
    downloadPDF: 'Download PDF',
    deleteAllConversations: 'Delete all conversations',
    conversationHistory: 'Conversation history',
    backToHistory: 'Back to history',
    termsOfUse: 'Terms of use',
    suggestImprovements: 'Suggest improvements',
  },
  feedback: {
    satisfactionLevel: 'What is your level of satisfaction with the CX Assistant?',
    improvementSuggestion: 'How can we improve?',
    placeholderComment: 'Leave your comment here...',
    submitButton: 'Submit',
  },
  chat: {
    noDataToExport: 'No data to export.',
    messageError: 'There was an error sending the message. Please try again.',
    monthlyCreditsExceeded: 'Your monthly credits are exhausted, contact sales for more information.',
    unableToProcessMessage: "Sorry, I couldn't process your message. Please try again.",
  },
  preparingChat: {
    preparingData: 'Preparing data for analysis...',
    analyzingData: 'The data displayed on the extract screen is being analyzed',
  },
  chatNew: {
    summaryComments: 'Summarize the comments',
    clientNeedsAttention: 'Which client needs more attention?',
    mostCriticizedItems: 'What are the most criticized items?',
    mostPraisedTopics: 'What are the most praised topics by clients?',
    mostCriticizedTopics: 'What are the most criticized topics by clients?',
    importantActions: 'What are the important actions to take?',
    filtersReflectOnAssistant: 'Filters applied in the extract reflect on the data analyzed by the assistant',
    aiNotPerfect: 'AI is not perfect, it may make mistakes',
    experienceLimit: 'There is currently a limit of 20000 experiences that the assistant can analyze',
    feedbackWelcome: 'Your feedback is welcome to help us improve the assistant',
  },
  chatBody: {
    userMessage: 'User message',
    botMessage: 'Bot message',
  },
  chatInput: {
    placeholder: 'Get your questions answered with the CX Assistant',
  },
  chatDisclaimer: {
    possibleErrors: 'CX Assistant may make mistakes. Consider verifying important information.',
    chatCredits: 'Chat credits',
    remainingCredits: 'Remaining credits',
  },
  threadHistory: {
    noConversations: 'There are no conversations in the history',
    deleteSuccess: 'Conversation successfully deleted',
    deleteAllSuccess: 'All conversations have been successfully deleted',
  },
};

export default en;
