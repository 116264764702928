<template>
  <div class="chat is-flex is-flex-direction-column">
    <b-loading :active="isLoading"></b-loading>
    <div v-if="messages.length > 0" class="has-text-centered pt-6 pb-2">
      <b-icon icon="sparkles" type="is-primary" custom-size="fa-4x" style="opacity: 0.5" custom-class="fa-sharp fa-regular"></b-icon>
      <p class="py-2 has-text-grey-lighter has-text-weight-bold">
        {{ $d(new Date(messages[0].time)) }}
      </p>
    </div>
    <div ref="chat-container" class="chat-container is-flex-grow-1 px-3 py-5">
      <ChatBody :messages="messages" />
    </div>
  </div>
</template>

<script lang="ts">
import ChatBody from '@/components/Chat/ChatBody.vue';
import { Messages, MessagesResponse } from '@/models/models';
import ThreadsService from '@/services/ThreadsService';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { ChatBody } })
export default class ThreadDetails extends Vue {
  @Inject() readonly threadsService!: ThreadsService;
  @Prop({ type: String, required: true }) public readonly threadId!: string;

  public messages: Messages[] = [];
  public isLoading = false;

  public mounted(): void {
    const threadId = this.threadId;
    if (threadId) {
      this.initializeThreadMessages();
    } else {
      console.error('Thread ID não encontrado.');
    }
  }

  public async initializeThreadMessages(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.threadsService.getThreadMessages(this.threadId);
      const mappedMessages = this.mapMessages(response);
      this.messages.push(...mappedMessages);
    } catch (error) {
      console.error('Erro ao inicializar a thread:', error);
    } finally {
      this.isLoading = false;
    }
  }

  public mapMessages(response: MessagesResponse[]): Messages[] {
    return response.map((msg) => ({
      author: msg.role === 'assistant' ? 'CX Assistant' : 'user',
      type: msg.role,
      time: msg.createdAt,
      content: msg.content,
    }));
  }
}
</script>
